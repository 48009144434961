<template>
  <div>
    <a-row :gutter="[16, 16]">
        <a-col :span="8">
          <card-stat cardType="primary">
            <span slot="title">Gajian di Periode ini</span>
            <span slot="value">{{statistik.tanggalGajian ? statistik.tanggalGajian : '-'}}</span>
          </card-stat>
        </a-col>
        <a-col :span="8">
          <card-stat cardType="warning">
            <span slot="title">Terlambat di Periode ini</span>
            <span slot="value">{{statistik.counterTerlambat ? statistik.counterTerlambat : '-'}}</span>
          </card-stat>
        </a-col>
        <a-col :span="8">
          <card-stat cardType="danger">
            <span slot="title">Absen di Periode ini</span>
            <span slot="value">{{statistik.counterAbsen ? statistik.counterAbsen : '-'}}</span>
          </card-stat>
        </a-col>
    </a-row>
    <a-row :gutter="[16, 16]" class="mt-3">
      <a-col :span="16">
        <div class="border rounded">
          <div class="p-4 border-bottom"><h3>Persentase Kehadiran (Bulanan %)</h3></div>
          <div class="p-4">
              <template v-if="ChartistData.labels.length && ChartistData.series.length">
                <line-chart
                  :chartData="ChartistData"
                  :chartOptions="ChartistOptions"
                />
              </template>
              <template v-else>
                <EmptyState
                  heading=""
                  description="Data Persentase Tidak Ditemukan"
                />
              </template>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="border rounded mb-4">
          <div class="p-4 border-bottom"><h3>Progres Periode {{periodeActive.bulan}} {{periodeActive.tahun}}</h3></div>
          <div class="p-4">
            <div class="d-flex justify-content-between align-items-center">
              <p>{{moment(periodeActive.tanggal_awal).format('DD MMMM YYYY') !== 'Invalid date' ? moment(periodeActive.tanggal_awal).locale('id').format('DD MMMM YYYY') : '-'}}</p>
              <p>s/d</p>
              <p>{{moment(periodeActive.tanggal_akhir).format('DD MMMM YYYY') !== 'Invalid date' ? moment(periodeActive.tanggal_akhir).locale('id').format('DD MMMM YYYY') : '-'}}</p>
            </div>
            <a-progress
              class="my-2"
              status="active"
              :show-info="false"
              :percent="periodeActive.counted/periodeActive.total*100"
            />
            <div class="d-flex justify-content-between align-items-center">
              <p><span class="text-primary font-weight-bold">{{periodeActive.counted}}</span> / {{periodeActive.total}} hari</p>
              <p>{{periodeActive.total - periodeActive.counted}} hari lagi</p>
            </div>
          </div>
        </div>
        <div class="border rounded">
          <div class="p-4 border-bottom"><h3>Shift Anda Saat Ini</h3></div>
          <div v-if="listShift">
            <div v-for="item in listShift.shift.jadwal_shifts" :key="item.id" class="rounded p-2 card-shift d-flex justify-content-between align-items-center my-3 mx-4">
              <div class="d-flex flex-column w-50">
                <p class="text-white  ">{{item.hari}}</p>
                <p class="text-white">{{listShift.shift.nama}}</p>
              </div>
              <p class="text-white font-weight-bold">({{moment(item.jam_masuk, 'HH:mm').format('HH:mm')}} - {{moment(item.jam_keluar, 'HH:mm').format('HH:mm')}})</p>
            </div>
          </div>
          <div v-else class="p-4">
            <EmptyState
              heading=""
              description="Data Shift Tidak Ditemukan"
            />
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
const CardStat = () => import('@/components/app/Shared/CardStat')
const LineChart = () => import('@/components/app/Shared/LineChart')
const EmptyState = () => import('@/components/app/Shared/EmptyState')
const ChartistOptions = {
  fullWidth: false,
  showArea: true,
  height: '400px',
  chartPadding: {
    right: 0,
    left: 0,
  },
  axisY: {
    labelInterpolationFnc: function(value) {
      return value + '%'
    },
  },
}
export default {
  components: {
    CardStat,
    LineChart,
    EmptyState,
  },
  data() {
    return {
      ChartistData: {
        labels: [],
        series: [],
      },
      ChartistOptions,
      listShift: null,
      listKegiatan: [],
      statistik: {
        tanggalGajian: null,
        counterTerlambat: null,
        counterAbsen: null,
      },
      periodeActive: {
        counted: 0,
        total: 0,
        bulan: null,
        tahun: null,
        tanggal_awal: null,
        tanggal_akhir: null,
      },
    }
  },
  methods: {
    moment,
    fetchDataStatistik() {
      this.$store.dispatch('absensiKaryawan/FETCH_STATISTIK_DASHBOARD', { idPegawai: this.user.id })
        .then(res => {
          console.log('res', res)
          this.statistik = res.statistik
          this.periodeActive = res.periodeActive
        })
    },
    fetchDataShiftPegawai() {
      this.$store.dispatch('shift/FETCH_SHIFT_PEGAWAI', { idPegawai: this.user.id })
        .then(res => {
          this.listShift = res.shiftPegawai
        })
    },
    fetchPersentaseKehadiran() {
      this.$store.dispatch('absensiKaryawan/FETCH_PERSENTASE_KEHADIRAN_BULANAN', { idPegawai: this.user.id })
        .then(res => {
          this.ChartistData = res
        })
    },
  },
  mounted() {
    this.fetchDataStatistik()
    this.fetchPersentaseKehadiran()
    this.fetchDataShiftPegawai()
  },
  computed: {
    user() { return this.$store.state.user.user },
  },
}
</script>

<style scoped>
.card-shift {
  background-color: #28527a;
  color: white;
}
</style>
